"use client";

import { cn } from "@/lib/utils";
import { Inter, Vazirmatn } from "next/font/google";
import { FC, HTMLAttributes } from "react";
const fontSans = Inter<"--font-sans">({
  subsets: ["latin"],
  preload: true,
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  variable: "--font-sans",
  fallback: ["system-ui", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"]
});
const fontFarsi = Vazirmatn<"--font-farsi">({
  subsets: ["arabic", "latin"],
  preload: true,
  weight: ["100", "200", "300", "400", "500", "600", "700", "800", "900"],
  fallback: ["var(--font-sans)", "sans-serif"],
  variable: "--font-farsi"
});
type BodyVariants = "kheradmen" | "debug-body" | undefined;
interface BodyProps extends HTMLAttributes<HTMLBodyElement> {
  variant?: BodyVariants;
}
export { type BodyProps };

// Component THAT LOADS IN FONTS
// THIS COMPONENT CAN NOT BE USED IN ANY CLIENT-SIDE COMPONENT
// IT IS BEING USED TO LOAD IN FONTS
const Body: FC<BodyProps> = ({
  children,
  className,
  variant = "kheradmen",
  ...rest
}) => {
  return <body className={cn("body flex flex-col relative font-sans rtl:font-farsi", fontSans.variable, fontFarsi.variable, variant && variant, className)} {...rest} data-sentry-component="Body" data-sentry-source-file="body.tsx">
      {children}
    </body>;
};
export default Body;