/** @format */

"use client";

import React, { createContext, useCallback, useContext, useEffect, useState, useTransition } from "react";
import { ThemeProvider } from "@/context/ThemeProvider";
import NextTopLoader from "nextjs-toploader";
import { Themes } from "@/config";
import Body, { BodyProps } from "@/components/layout/body";
import { CookieKeys } from "@/cookies/types";
import { useCookie } from "@/hooks/use-cookie";
import CookieConsent from "@/components/common/CookieConsent";
import { defaultLocale, Locale } from "@/i18n-config";
import { usePathname, useRouter } from "@/navigation";
import { useParams, useSearchParams } from "next/navigation";
import { useMediaQuery } from "@/hooks/use-media-query";
import { NEXT_PUBLIC_BACKEND_API } from "@/env";
import { isAdmin, UserEntity } from "./sekkei.type";
import { SekkeiProvider } from "./SekkeiProvider";
import md5 from "md5";
interface AppContextType {
  hasAcceptedCookies: boolean;
  acceptCookies: () => void;
  locale: Locale;
  switchLocaleTo: (targetLocale: Locale) => void;
  localePending: boolean;
  allowedLocales: Locale[];
  authStatus: "loading" | "loaded" | "error";
  isAuthenticated: boolean;
  login: (token: string, expiresAt: Date, user?: UserEntity) => void;
  logout: () => void;
  user: () => UserEntity | null;
  checkAuth: () => Promise<void>;
  isDarkModePereferred: boolean;

  // user tracking
}
const getGravatarUrl = (email: string, size: number = 200): string => {
  const hash = md5(email.toLowerCase().trim());
  return `https://www.gravatar.com/avatar/${hash}?s=${size}&d=identicon`;
};
const AppContext = createContext<AppContextType | undefined>(undefined);
export const useApp = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useApp must be used within an AppProvider");
  }
  return context;
};
interface AppProviderProps extends BodyProps {
  children: React.ReactNode;
  defaultTheme: Themes;
  themes: Themes[];
}
export const AppProvider: React.FC<AppProviderProps> = ({
  children,
  defaultTheme,
  themes,
  ...rest
}) => {
  const [cookieConsent, setCookieConsent] = useCookie(CookieKeys.Consent, null);
  const [locale, setLocale] = useCookie(CookieKeys.Locale, defaultLocale);
  const isDark = useMediaQuery("(prefers-color-scheme: dark)");
  const [authStatus, setAuthStatus] = useState<"loading" | "loaded" | "error">("loading");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const queryString = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const [pending, startTransition] = useTransition();
  const params = useParams();
  const [allowedLocales, setAllowedLocales] = useState<Locale[]>([defaultLocale]);
  useEffect(() => {
    fetch<{
      locales: Locale[];
    }>("/locales").then(r => r.json()).then(r => {
      setAllowedLocales(r.locales);
    });
  }, []);
  const handleSwitchLocale = useCallback((target: Locale) => {
    if (!allowedLocales.includes(target)) {
      console.warn("Trying to switch to invalid locale");
      return;
    }
    startTransition(() => {
      setLocale(target);
      router.replace(
      // @ts-expect-error -- TypeScript will validate that only known `params`
      // are used in combination with a given `pathname`. Since the two will
      // always match for the current route, we can skip runtime checks.
      {
        pathname,
        params
      }, {
        locale: target
      });
    });
  }, [allowedLocales, setLocale, router, pathname, params]);
  const login = useCallback(async (token: string, expiresAt: Date, user?: UserEntity) => {
    setAuthStatus("loading");
    try {
      localStorage.setItem("authToken", token);
      localStorage.setItem("authExpires", expiresAt.toISOString());
      setIsAuthenticated(true);
      if (user) {
        localStorage.setItem("user", JSON.stringify(user));
        const redirectPath = isAdmin(user.role) ? "/admin/dashboard" : "/dashboard";
        router.push(queryString.get("redirect") || redirectPath);
      }
      setAuthStatus("loaded");
    } catch (error) {
      console.error("Error during login:", error);
      setAuthStatus("error");
    }
  }, [router, queryString]);
  const logout = useCallback(async () => {
    setAuthStatus("loading");
    try {
      localStorage.removeItem("authToken");
      localStorage.removeItem("authExpires");
      localStorage.removeItem("user");
      setIsAuthenticated(false);
      setAuthStatus("loaded");
    } catch (error) {
      console.error("Error during logout:", error);
      setAuthStatus("error");
    }
  }, []);
  const checkAuth = useCallback(async () => {
    setAuthStatus("loading");
    try {
      const token = localStorage.getItem("authToken");
      const expiresAt = localStorage.getItem("authExpires");
      if (token && expiresAt && new Date(expiresAt) > new Date()) {
        setIsAuthenticated(true);
      } else {
        console.debug(["Token not found", token, expiresAt, new Date(expiresAt!), new Date()]);
        await logout();
      }
      setAuthStatus("loaded");
    } catch (error) {
      console.error("Error checking authentication:", error);
      setAuthStatus("error");
    }
  }, [logout]);
  const authGetter = useCallback(async () => {
    checkAuth();
    if (!isAuthenticated) {
      console.log("Auth not authenticated, for some reason ??");
      // return the authToken for later to fix.
      return localStorage.getItem("authToken");
    }
    return localStorage.getItem("authToken");
  }, [isAuthenticated, checkAuth]);
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);
  useEffect(() => {
    const url = pathname + queryString.toString();
    if (cookieConsent === "accepted") {
      // TODO: track user
      console.log("Visited", {
        url
      });
    }
  }, [pathname, queryString, cookieConsent]);
  const user = useCallback((): UserEntity | null => {
    if (!isAuthenticated) {
      return null;
    }
    const storedUser = localStorage.getItem("user");
    if (!storedUser) {
      return null;
    }
    try {
      const parsedUser = JSON.parse(storedUser) as UserEntity;
      return {
        id: parsedUser.id,
        email: parsedUser.email,
        name: parsedUser.name,
        number: parsedUser.number,
        project: parsedUser.project,
        role: parsedUser.role,
        balance: parsedUser.balance,
        notes: parsedUser.notes,
        status: parsedUser.status,
        profile: getGravatarUrl(parsedUser.email)
      } as UserEntity;
    } catch (error) {
      console.error("Error parsing user data:", error);
      return null;
    }
  }, [isAuthenticated]);
  return <Body {...rest} data-sentry-element="Body" data-sentry-component="AppProvider" data-sentry-source-file="AppProvider.tsx">
      <AppContext.Provider value={{
      hasAcceptedCookies: cookieConsent === "accepted",
      acceptCookies: () => setCookieConsent("accepted", {
        expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000),
        // 90 days
        secure: true,
        sameSite: "lax"
      }),
      locale: locale,
      switchLocaleTo: handleSwitchLocale,
      localePending: pending,
      allowedLocales,
      authStatus,
      isAuthenticated: isAuthenticated,
      login,
      logout,
      user,
      isDarkModePereferred: isDark,
      checkAuth
    }} data-sentry-element="unknown" data-sentry-source-file="AppProvider.tsx">
        <SekkeiProvider baseApiUrl={NEXT_PUBLIC_BACKEND_API} authGetter={authGetter} data-sentry-element="SekkeiProvider" data-sentry-source-file="AppProvider.tsx">
          <ThemeProvider attribute="class" defaultTheme={defaultTheme} enableSystem themes={themes} data-sentry-element="ThemeProvider" data-sentry-source-file="AppProvider.tsx">
            <NextTopLoader data-sentry-element="NextTopLoader" data-sentry-source-file="AppProvider.tsx" />
            {children}
            <CookieConsent data-sentry-element="CookieConsent" data-sentry-source-file="AppProvider.tsx" />
          </ThemeProvider>
        </SekkeiProvider>
      </AppContext.Provider>
    </Body>;
};