/** @format */

import { ApiMethodConfig } from "./SekkeiProvider";
import {
  RegisterUser,
  SubscribeUser,
  SubscribeContact,
  RegisterValidation,
  PhoneAuth,
  Login,
  LoginResponse,
  BlogPostRequest,
  BlogPost,
  BlogPostResult,
  FileUploadResponse,
  ContentAnalysisRequest,
  ContentAnalysisResponse,
  NewPostResponse,
  NewPostRequest,
  NewDOIRequest,
  NewDOIResponse,
  DOIRequest,
  DOIResult,
  CategoryListResponse,
} from "./sekkei.type";

const API_PATHS = {
  REGISTER: "/api/v1/auth/register",
  SUBSCRIBE: "/api/v1/subscribe",
  REGISTER_VALIDATION: "/api/v1/auth/register/validation",
  TOKEN: "/api/v1/auth/token",
  CODE: "/api/v1/auth/phone",
  BLOG_SEARCH: "/api/v1/blog/search",
  BLOG_FETCH: "/api/v1/blog/",
  FILE_UPLOAD: "/api/v1/file/upload",
  CONTENT_ANALYSIS: "/api/v1/content-analysis",
  BLOG_CREATE: "/api/v1/blog/create",
  DOI_CREATE: "/api/v1/doi/create",
  DOI_SEARCH: "/api/v1/doi/search",
  CATEGORY_LIST: "/api/v1/category",
};

export const apiMethods = {
  register: {
    endpoint: API_PATHS.REGISTER,
    method: "POST",
    buildBody: (data: RegisterUser) => data,
    requiresAuth: false,
  } as ApiMethodConfig<{ token: string }, [RegisterUser]>,

  subscribe: {
    endpoint: API_PATHS.SUBSCRIBE,
    method: "POST",
    buildBody: (data: SubscribeUser | SubscribeContact) => data,
    requiresAuth: false,
  } as ApiMethodConfig<{ status: boolean }, [SubscribeUser | SubscribeContact]>,

  registerValidation: {
    endpoint: API_PATHS.REGISTER_VALIDATION,
    method: "POST",
    buildBody: (data: RegisterValidation) => data,
    requiresAuth: false,
  } as ApiMethodConfig<unknown, [RegisterValidation]>,

  loginPhoneAuth: {
    endpoint: API_PATHS.CODE,
    method: "POST",
    buildBody: (data: PhoneAuth) => data,
    requiresAuth: false,
  } as ApiMethodConfig<{ token: string }, [PhoneAuth]>,

  loginSubmit: {
    endpoint: API_PATHS.TOKEN,
    method: "POST",
    buildBody: (data: Login) => data,
    requiresAuth: false,
  } as ApiMethodConfig<LoginResponse, [Login]>,

  blogFetch: {
    endpoint: API_PATHS.BLOG_SEARCH,
    method: "GET",
    buildBody: (data: BlogPostRequest) => {
      const queryParams = new URLSearchParams();
      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          if (Array.isArray(value)) {
            queryParams.append(key, value.join(","));
          } else {
            queryParams.append(key, value.toString());
          }
        }
      });
      return queryParams.toString();
    },
    requiresAuth: false,
  } as ApiMethodConfig<BlogPostResult, [BlogPostRequest]>,

  blogSingle: {
    endpoint: (slug: string, locale: string) =>
      `${API_PATHS.BLOG_FETCH}${slug}?locale=${locale}`,
    method: "GET",
    requiresAuth: false,
  } as ApiMethodConfig<BlogPost, [string, string]>,

  fileUpload: {
    endpoint: API_PATHS.FILE_UPLOAD,
    method: "POST",
    buildBody: (data: FormData) => data,
    requiresAuth: true,
    formData: true,
  } as ApiMethodConfig<FileUploadResponse, [FormData]>,

  categoryList: {
    endpoint: API_PATHS.CATEGORY_LIST,
    method: "GET",
    requiresAuth: true,
  } as ApiMethodConfig<CategoryListResponse, []>,

  contentAnalysis: {
    endpoint: API_PATHS.CONTENT_ANALYSIS,
    method: "POST",
    buildBody: (data: ContentAnalysisRequest) => data,
    requiresAuth: true,
  } as ApiMethodConfig<ContentAnalysisResponse, [ContentAnalysisRequest]>,

  blogCreate: {
    endpoint: API_PATHS.BLOG_CREATE,
    method: "POST",
    buildBody: (data: NewPostRequest) => data,
    requiresAuth: true,
  } as ApiMethodConfig<NewPostResponse, [NewPostRequest]>,

  doiCreate: {
    endpoint: API_PATHS.DOI_CREATE,
    method: "POST",
    buildBody: (data: NewDOIRequest) => data,
    requiresAuth: true,
  } as ApiMethodConfig<NewDOIResponse, [NewDOIRequest]>,

  doiSearch: {
    endpoint: API_PATHS.DOI_SEARCH,
    method: "GET",
    buildBody: (data: DOIRequest) => {
      const queryParams = new URLSearchParams();
      Object.entries(data).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          if (Array.isArray(value)) {
            queryParams.append(key, value.join(","));
          } else {
            queryParams.append(key, value.toString());
          }
        }
      });
      return queryParams.toString();
    },
    requiresAuth: false,
  } as ApiMethodConfig<DOIResult, [DOIRequest]>,
} as const;
