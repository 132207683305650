/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
"use client";

import React, { createContext, useContext, useState, ReactNode } from "react";
import { apiMethods } from "./sekkei";
export class APIError extends Error {
  public response: Response;
  public data: any;
  constructor(message: string, response: Response, data?: any) {
    super(message);
    this.name = "APIError";
    this.response = response;
    this.data = data;
  }
  public get status(): number {
    return this.response.status;
  }
  public get statusText(): string {
    return this.response.statusText;
  }
  public static async fromResponse(response: Response): Promise<APIError> {
    let data;
    let message = `API Error: ${response.status} ${response.statusText}`;
    try {
      data = await response.json();
      if (data && typeof data.message === "string") {
        message = data.message;
      }
    } catch (e) {
      // If parsing JSON fails, we'll use the default message
    }
    return new APIError(message, response, data);
  }
}

// Define the shape of your API response
interface ApiState<TResponse, TParams extends unknown[]> {
  data: TResponse | null;
  isLoading: boolean;
  error: Error | null;
  execute: (...args: TParams) => Promise<TResponse>;
}
// Define the available HTTP methods
type HttpMethod = "GET" | "POST" | "PUT" | "DELETE";

// Define the structure for API method configuration
// @ts-expect-error TResponse is used, but in an implicit way.
interface ApiMethodConfig<TResponse, TParams extends unknown[]> {
  endpoint: string | ((...args: TParams) => string);
  method: HttpMethod;
  headers?: Record<string, string> | ((...args: TParams) => Record<string, string>);
  buildBody?: (...args: TParams) => unknown;
  requiresAuth: boolean;
  formData?: boolean;
}

// Type for the API methods object
type ApiMethods = {
  [key: string]: ApiMethodConfig<any, any[]>;
};

// Helper type to infer the structure of our specific apiMethods object
type InferApiMethods<T> = { [K in keyof T]: T[K] extends ApiMethodConfig<infer R, infer P> ? ApiMethodConfig<R, P> : never };

/**
 * Helper function to create an API call
 * @param config - The configuration for the API method
 * @param getAuthToken - Function to retrieve the authentication token
 * @param baseApiUrl - The base URL for the API
 * @returns A function that executes the API call
 */
const createApiCall = <TResponse, TParams extends unknown[]>(config: ApiMethodConfig<TResponse, TParams>, getAuthToken: () => Promise<string | null>, baseApiUrl: string) => async (...args: TParams): Promise<TResponse> => {
  // Determine the endpoint
  let endpoint = typeof config.endpoint === "function" ? config.endpoint(...args) : config.endpoint;

  // Prepare the request options
  const options: RequestInit = {
    method: config.method,
    headers: {
      ...(config.method !== "GET" && {
        "Content-Type": "application/json"
      }),
      ...(typeof config.headers === "function" ? config.headers(...args) : config.headers)
    }
  };
  if (config.formData) {
    const fd = new FormData();
    Object.entries(config.formData).forEach(([key, value]) => {
      if (value instanceof File) {
        fd.append(key, value);
      } else {
        fd.append(key, value.toString());
      }
    });
    options.body = fd;
    options.headers = {
      ...options.headers,
      "Content-Type": "multipart/form-data"
    };
  }

  // Add authentication token if required
  if (config.requiresAuth) {
    const token = await getAuthToken();
    if (!token) {
      throw new Error("Authentication required");
    }
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`
    };
  }

  // Handle request body or query parameters
  if (config.buildBody) {
    const builtBody = config.buildBody(...args);
    if (config.method === "GET") {
      // For GET requests, append the query string to the URL
      const queryParams = new URLSearchParams(builtBody as Record<string, string>).toString();
      endpoint += (endpoint.includes("?") ? "&" : "?") + queryParams;
    } else {
      // For other methods, add the body to the request
      options.body = JSON.stringify(builtBody);
    }
  }

  // Execute the fetch request
  const response = await fetch(new URL(endpoint, baseApiUrl).toString(), options);
  if (!response.ok) {
    throw await APIError.fromResponse(response);
  }
  return await response.json();
};

/**
 * Function to create the Sekkei context and provider
 * @param apiMethods - Object containing API method configurations
 * @returns An object with SekkeiProvider and useSekkei hook
 */
function createSekkeiContext<T extends ApiMethods>(apiMethods: InferApiMethods<T>) {
  // Extract return types of API methods
  type MethodReturnTypes = { [K in keyof T]: T[K] extends ApiMethodConfig<infer R, any[]> ? R : never };

  // Extract parameter types of API methods
  type MethodParamTypes = { [K in keyof T]: T[K] extends ApiMethodConfig<any, infer P> ? P : never };

  // Define the shape of the Sekkei context
  type SekkeiContextType = { [K in keyof T]: () => ApiState<MethodReturnTypes[K], MethodParamTypes[K]> };

  // Create the context
  const SekkeiContext = createContext<SekkeiContextType | undefined>(undefined);

  /**
   * SekkeiProvider component
   * @param props - Component props
   * @param props.children - Child components
   * @param props.authGetter - Function to retrieve the authentication token
   * @param props.baseApiUrl - Base URL for the API
   */
  function SekkeiProvider({
    children,
    authGetter,
    baseApiUrl
  }: {
    children: ReactNode;
    authGetter: () => Promise<string | null>;
    baseApiUrl: string;
  }) {
    // Create API methods with their respective states
    const methods = Object.entries(apiMethods).reduce((acc, [key, config]) => {
      const useApiState = () => {
        type ResponseType = MethodReturnTypes[typeof key];
        type ParamsType = MethodParamTypes[typeof key];

        // Create state for the API method
        const [state, setState] = useState<ApiState<ResponseType, ParamsType>>({
          data: null,
          isLoading: false,
          error: null,
          execute: async (...args: ParamsType) => {
            setState(prev => ({
              ...prev,
              isLoading: true,
              error: null
            }));
            try {
              const data = await createApiCall<ResponseType, ParamsType>(config as ApiMethodConfig<ResponseType, ParamsType>, authGetter, baseApiUrl)(...args);
              setState(prev => ({
                ...prev,
                data,
                isLoading: false,
                error: null
              }));
              return data;
            } catch (error) {
              const newError = error instanceof Error ? error : new Error("An unknown error occurred");
              setState(prev => ({
                ...prev,
                isLoading: false,
                error: newError
              }));
              throw newError;
            }
          }
        });
        return state;
      };
      acc[key as keyof T] = useApiState;
      return acc;
    }, {} as SekkeiContextType);
    return <SekkeiContext.Provider value={methods} data-sentry-element="unknown" data-sentry-component="SekkeiProvider" data-sentry-source-file="SekkeiProvider.tsx">
        {children}
      </SekkeiContext.Provider>;
  }

  /**
   * Custom hook to use the Sekkei context
   * @returns The Sekkei context
   * @throws Error if used outside of SekkeiProvider
   */
  function useSekkei() {
    const context = useContext(SekkeiContext);
    if (context === undefined) {
      throw new Error("useSekkei must be used within a SekkeiProvider");
    }
    return context;
  }
  return {
    SekkeiProvider,
    useSekkei
  };
}

// Create and export the Sekkei context and hooks
export const {
  SekkeiProvider,
  useSekkei
} = createSekkeiContext(apiMethods);

// Export types for external use
export type SekkeiMethods = typeof apiMethods;
export type SekkeiHooks = ReturnType<typeof useSekkei>;
export { type ApiMethods, type ApiMethodConfig };