/** @format */

"use client";

import { Button } from "@/components/ui/button";
import { useApp } from "@/context/AppProvider";
import { Link } from "@/navigation";
import { AnimatePresence, motion } from "framer-motion";
import { X } from "lucide-react";
import { useLocale, useTranslations } from "next-intl";
import React, { memo, useState } from "react";
const CookieConsent: React.FC = () => {
  const t = useTranslations("CookieConsent");
  const locale = useLocale();
  const {
    hasAcceptedCookies,
    acceptCookies
  } = useApp();
  const [isVisible, setIsVisible] = useState(!hasAcceptedCookies);
  if (hasAcceptedCookies || !isVisible) return null;
  return <AnimatePresence data-sentry-element="AnimatePresence" data-sentry-component="CookieConsent" data-sentry-source-file="CookieConsent.tsx">
      <motion.div initial={{
      y: 50,
      opacity: 0
    }} animate={{
      y: 0,
      opacity: 1
    }} exit={{
      y: 50,
      opacity: 0
    }} transition={{
      type: "spring",
      stiffness: 400,
      damping: 30
    }} className="fixed z-50 bottom-4 inset-x-4 md:right-4 md:left-auto md:max-w-sm" data-sentry-element="unknown" data-sentry-source-file="CookieConsent.tsx">
        <div className="relative p-4 border rounded-lg shadow-lg bg-white/90 dark:bg-black/90 backdrop-blur-md border-border">
          <Button variant="ghost" size="icon" className="absolute top-2 right-2 text-muted-foreground hover:text-foreground" onClick={() => setIsVisible(false)} data-sentry-element="Button" data-sentry-source-file="CookieConsent.tsx">
            <X size={16} data-sentry-element="X" data-sentry-source-file="CookieConsent.tsx" />
          </Button>
          <p className="pr-6 mb-4 text-sm text-muted-foreground">
            {t("description")}{" "}
            <Link href={`/${locale}/cookie-policy`} className="text-primary hover:underline" data-sentry-element="Link" data-sentry-source-file="CookieConsent.tsx">
              {t("learnMore")}
            </Link>
          </p>
          <div className="flex justify-end space-x-2 rtl:space-x-reverse">
            <Button variant="outline" size="sm" onClick={() => setIsVisible(false)} data-sentry-element="Button" data-sentry-source-file="CookieConsent.tsx">
              {t("dismiss")}
            </Button>
            <Button size="sm" onClick={acceptCookies} data-sentry-element="Button" data-sentry-source-file="CookieConsent.tsx">
              {t("accept")}
            </Button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>;
};
export default memo(CookieConsent);