"use client";

import { ThemeProvider as NextThemesProvider, useTheme as NextUseTheme } from 'next-themes';
import type { ThemeProviderProps } from "next-themes/dist/types";
export function ThemeProvider({
  children,
  ...props
}: ThemeProviderProps) {
  return <NextThemesProvider {...props} data-sentry-element="NextThemesProvider" data-sentry-component="ThemeProvider" data-sentry-source-file="ThemeProvider.tsx">{children}</NextThemesProvider>;
}
export function useTheme() {
  return NextUseTheme();
}