/** @format */

import { Locale } from "@/i18n-config";

// User-related types
export type Role =
  | "full_admin"
  | "admin"
  | "blog_post"
  | "support_admin"
  | "vip_user"
  | "user";
export type Status = "pending_email" | "pending_sms" | "active";

export interface UserBase {
  id: string;
  email: string;
  name: string;
  number: string; // E-164 format
  project: string;
}

export interface UserEntity extends UserBase {
  role: Role;
  balance: number;
  notes: string;
  status: Status;
  profile: string;
}

type AdminRole = "full_admin" | "admin" | "blog_post" | "support_admin";

export function isAdmin(role: Role): role is AdminRole {
  return (
    role === "full_admin" ||
    role === "admin" ||
    role === "blog_post" ||
    role === "support_admin"
  );
}

export type RegisterUser = Omit<UserBase, "id" | "name"> & {
  password: string;
  verify_type: "sms" | "email";
  full_name: string;
};

// Authentication types
export type VerificationType = "email" | "phone";

export interface RegisterValidation {
  type: VerificationType;
  hash: string;
  code: string;
}

export type GrantType = "phone_code" | "password" | "refresh_token";

export interface LoginBase {
  grant_type: GrantType;
}

export interface PhoneCodeLogin extends LoginBase {
  grant_type: "phone_code";
  phone: string;
  code: string;
}

export interface PasswordLogin extends LoginBase {
  grant_type: "password";
  username: string;
  password: string;
}

export interface RefreshTokenLogin extends LoginBase {
  grant_type: "refresh_token";
  refresh_token: string;
}

export type Login = PhoneCodeLogin | PasswordLogin | RefreshTokenLogin;

export interface PhoneAuth {
  phone: string;
  project?: string;
}

export interface LoginResponse {
  session_id: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
  scope: string;
  created_at: number;
  user: UserEntity;
}

// Blog-related types
export type PostStatus = "draft" | "published" | "deleted" | "archived";

export interface BlogPostRequest {
  locale?: Locale;
  slug?: string;
  title?: string;
  category?: string;
  content?: string;
  tags?: string[];
}

export interface DOIRequest {
  doi: string;
}

export interface Category {
  id: string;
  tag: string;
  name: string;
  description: string;
}

export interface Tag {
  id: string;
  name: string;
}

export interface BlogPost {
  id: string;
  slug: string;
  image?: string;
  title: string;
  description: string;
  content: string;
  author?: UserEntity;
  category: Category;
  status: PostStatus;
  view: number;
  ttr: number;
  locale: string;
  created_at: string;
  modified_at?: string;
  published_at?: string;
  tags: Array<Tag>;
  word_count: number;
}

export interface BlogPostResult {
  Posts: BlogPost[];
}

export interface CategoryListResponse {
  Categories: Category[];
}

export interface DOIResult {
  dois: DOI[];
}

export interface DOI {
  doi_link: string;
}

// Subscription types
export interface SubscribeUser {
  email: string;
  type: "newsletter";
}

export interface SubscribeContact {
  email: string;
  subject: string;
  message: string;
  name: string;
  type: "contact";
}

// File upload types
export interface File {
  fileName: string;
  mimeType: string;
  hash: string;
}

export interface FileUploadResponse {
  message: string;
  files: File[];
}

// Content analysis types
export interface ContentAnalysisRequest {
  content: string;
}

export interface ContentAnalysisResponse {
  title: string;
  description: string;
  tags: string[];
}

export interface NewDOIRequest {
  doi_link: string;
}

export interface NewPostRequest {
  title: string;
  slug?: string;
  description: string;
  content: string;
  locale: Locale;
  category: string;
  tags: string[];
  image: string;
  status: "draft" | "published";
  publishDate?: string; // ISO 8601 date string
  author?: string;
  readTime?: number;
  metaTitle?: string;
  metaDescription?: string;
  canonicalUrl?: string;
  isFeatureBlog?: boolean;
  isSponsoredBlog?: boolean;
}

// Response type for blog creation
export interface NewPostResponse {
  id: string;
  slug: string;
  // You can add more fields here if the API returns additional information
}

export interface NewDOIResponse {}
